/**
 * Belege
 * Die Belege aus dem Belegbuch in ADNOVA+ können mittels dieser API bearbeitet, geheftet, entheftet sowie festgeschrieben und storniert werden. Des Weiteren können auch neue Belege importiert werden. Der Import läuft über eine Queue. Daher kann nach dem Aufruf der \"createBeleg\" - Schnittstelle über die Schnittstelle \"importstatus\", mittels der InhaltId, der Status abgefragt werden. Enthält der Importstatus eine BelegId ist der Beleg erfolgreich angelegt worden und kann über diese BelegId abgerufen werden. Damit der Beleg vollständig bearbeitet und ergänzt werden kann, lassen sich die nötigen Daten zu Vorgangsarten, Stichworte, Label, Ordner, Erläuterungen und FreitextPartner abrufen und bearbeiten. Das Heften und Entheften von Belegen ist ebenfalls möglich,. Beim Entheften ist darauf zu achten, dass die einzelnen Seiten wie neue Belege importiert werden. Daher müssen über den Importstatus die neuen BelegIds abgefragt werden, wenn der Beleg fertig entheftet ist. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BelegDTO } from '../model/models';
import { BelegSuggestionDTO } from '../model/models';
import { CountBelegResponseDTO } from '../model/models';
import { CreateBelegRequestDTO } from '../model/models';
import { CreateBelegResponseDTO } from '../model/models';
import { EntheftenResponseDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { ErrorMessageDubletteDTO } from '../model/models';
import { FilterBelegDTO } from '../model/models';
import { HeftenRequestDTO } from '../model/models';
import { HeftenResponseDTO } from '../model/models';
import { ImportDataRequestDTO } from '../model/models';
import { ImportStatusDTO } from '../model/models';
import { PageableBelegDTO } from '../model/models';
import { ReverseBelegRequestDTO } from '../model/models';
import { ReverseBelegResultDTO } from '../model/models';
import { StipulateBelegResultDTO } from '../model/models';
import { UpdateBelegRequestDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BelegService {

    protected basePath = 'https://api.adnova.de/beleg-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Lesen der Anzahl der Belege
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegFilter Objekt für die Filterung von Belegen.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countBelege(inhaberId: string, belegFilter?: FilterBelegDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CountBelegResponseDTO>;
    public countBelege(inhaberId: string, belegFilter?: FilterBelegDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CountBelegResponseDTO>>;
    public countBelege(inhaberId: string, belegFilter?: FilterBelegDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CountBelegResponseDTO>>;
    public countBelege(inhaberId: string, belegFilter?: FilterBelegDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling countBelege.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (belegFilter !== undefined && belegFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>belegFilter, 'belegFilter');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CountBelegResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/count`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Anlegen eines Belegs
     * Legt einen neuen Beleg an. Es ist möglich die Daten des Beleges auszulassen. Diese werden dann automatisch aus der Datei ermittelt und vorgeschlagen, müssen später aber nachbearbeitet werden. Ein Beleg ohne Datei ist nicht möglich. Über die Importdaten können optional Informationen für den Import mitgegeben werden. Es kommt eine UUID für den Inhalt des Belegs zurück. Über die Schnittstelle /importstatus/{inhaltId} kann sich der aktuelle Status des Imports abgerufen werden. Der Beleg ist angelegt, sobald der Importstatus eine BelegId enthält. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param file Dateiinhalt zu einem Beleg.&lt;br&gt; Hiermit sind die Binärdaten gemeint.&lt;br&gt; Es werden folgende Dateitypen für den Upload unterstützt:&lt;br&gt; - .bmp - .csv - .doc - .docx - .docm - .eml - .gif - .htm - .html - .jpeg - .jpg - .msg - .odc - .odf - .odg - .odi - .odm - .odp - .ods - .odt - .oft - .otg - .otp - .ots - .ott - .pdf - .png - .ppt - .pptx - .tif - .tiff - .txt - .xls - .xlsm - .xlsx - .xml Es wird basierend auf dem Inhalt (Magic Bytes) und über den ursprünglichen Dateinamen (wenn nicht über Magic Bytes möglich oder uneindeutig) der MIME-Type bestimmt. Basierend auf diesem MIME-Type wird geprüft, ob die Datei zulässig ist. 
     * @param data 
     * @param importData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBeleg(inhaberId: string, file: Blob, data?: CreateBelegRequestDTO, importData?: ImportDataRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreateBelegResponseDTO>;
    public createBeleg(inhaberId: string, file: Blob, data?: CreateBelegRequestDTO, importData?: ImportDataRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreateBelegResponseDTO>>;
    public createBeleg(inhaberId: string, file: Blob, data?: CreateBelegRequestDTO, importData?: ImportDataRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreateBelegResponseDTO>>;
    public createBeleg(inhaberId: string, file: Blob, data?: CreateBelegRequestDTO, importData?: ImportDataRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling createBeleg.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling createBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', useForm ? new Blob([JSON.stringify(data)], {type: 'application/json'}) : <any>data) as any || formParams;
        }
        if (importData !== undefined) {
            formParams = formParams.append('importData', useForm ? new Blob([JSON.stringify(importData)], {type: 'application/json'}) : <any>importData) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreateBelegResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Löschen eines Belegs
     * Löschen eines Belegs zu der UUID. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBeleg(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteBeleg(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteBeleg(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteBeleg(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling deleteBeleg.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling deleteBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Entheften eines Belegs
     * Entheftet einen mehrseitigen Beleg in einzelne Belege. Die neuen Belege werden neu importiert, damit die Vorschlagswerte ermittelt werden, daher kommen nur neue InhaltIds zurück. Über die Schnittstelle /importstatus/{inhaltId} kann der aktuelle Status des enthefteten Belegs abgerufen werden. Der Beleg ist angelegt, sobald der Importstatus eine BelegId enthält. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public entheften(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<EntheftenResponseDTO>;
    public entheften(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<EntheftenResponseDTO>>;
    public entheften(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<EntheftenResponseDTO>>;
    public entheften(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling entheften.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling entheften.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EntheftenResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/entheften`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abruf eines Belegs
     * Laden eines Beleges zu der UUID. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBeleg(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BelegDTO>;
    public getBeleg(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BelegDTO>>;
    public getBeleg(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BelegDTO>>;
    public getBeleg(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getBeleg.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BelegDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Herunterladen eines Belegs
     * Laden einer Datei über die UUID. Wenn der Beleg durch die Texterkennung verarbeitet wurde, so wird die daraus resultierende Datei geladen. Ansonsten wird die Originaldatei (ggf. ohne Text) geladen.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBelegContent(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<Blob>;
    public getBelegContent(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getBelegContent(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getBelegContent(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getBelegContent.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getBelegContent.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/content`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Herunterladen des Belegs mit erkanntem Text
     * Laden der aus der Texterkennung resultierenden Datei über die UUID.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBelegContentEnhanced(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<Blob>;
    public getBelegContentEnhanced(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getBelegContentEnhanced(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getBelegContentEnhanced(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getBelegContentEnhanced.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getBelegContentEnhanced.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/content/enhanced`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Herunterladen des Belegs mit erkanntem Text
     * Laden der aus der Texterkennung resultierenden Datei über die UUID.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public getBelegContentOcr(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<Blob>;
    public getBelegContentOcr(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getBelegContentOcr(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getBelegContentOcr(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getBelegContentOcr.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getBelegContentOcr.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/content/ocr`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Herunterladen des Originalbelegs
     * Laden der Originaldatei über die UUID
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBelegContentOriginal(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<Blob>;
    public getBelegContentOriginal(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getBelegContentOriginal(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getBelegContentOriginal(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getBelegContentOriginal.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getBelegContentOriginal.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/content/original`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Vorschlagswerte zu einem Beleg
     * Laden von Vorschlagswerten zu einem Beleg.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBelegSuggestions(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BelegSuggestionDTO>;
    public getBelegSuggestions(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BelegSuggestionDTO>>;
    public getBelegSuggestions(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BelegSuggestionDTO>>;
    public getBelegSuggestions(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getBelegSuggestions.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getBelegSuggestions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BelegSuggestionDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/suggestions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen des aktuellen Importstatus eines Belegs
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param inhaltId Id die einen Inhalt/Datei identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImportStatus(inhaberId: string, inhaltId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ImportStatusDTO>;
    public getImportStatus(inhaberId: string, inhaltId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ImportStatusDTO>>;
    public getImportStatus(inhaberId: string, inhaltId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ImportStatusDTO>>;
    public getImportStatus(inhaberId: string, inhaltId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getImportStatus.');
        }
        if (inhaltId === null || inhaltId === undefined) {
            throw new Error('Required parameter inhaltId was null or undefined when calling getImportStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ImportStatusDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/importstatus/${encodeURIComponent(String(inhaltId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Heften von Belegen
     * Heftet mehrere einzelne Belege zu einem Beleg zusammen. Die Reihenfolge der Belege gibt die Reihenfolge der Seiten des neuen Belegs an. Eigenbelege können nicht geheftet werden. Heften von Eigenbelegen wird mit einem Fehler beantwortet. Über die API ist nicht erkennbar ob es sich bei einem Beleg um einen Eigenbeleg handelt. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param heftenRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heften(inhaberId: string, heftenRequestDTO: HeftenRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HeftenResponseDTO>;
    public heften(inhaberId: string, heftenRequestDTO: HeftenRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<HeftenResponseDTO>>;
    public heften(inhaberId: string, heftenRequestDTO: HeftenRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<HeftenResponseDTO>>;
    public heften(inhaberId: string, heftenRequestDTO: HeftenRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling heften.');
        }
        if (heftenRequestDTO === null || heftenRequestDTO === undefined) {
            throw new Error('Required parameter heftenRequestDTO was null or undefined when calling heften.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<HeftenResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/heften`,
            heftenRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verschieben eines Belegs
     * Verschiebt einen Beleg von einem Inhaber in einen anderen. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param destinationInhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveBeleg(inhaberId: string, belegId: string, destinationInhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public moveBeleg(inhaberId: string, belegId: string, destinationInhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public moveBeleg(inhaberId: string, belegId: string, destinationInhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public moveBeleg(inhaberId: string, belegId: string, destinationInhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling moveBeleg.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling moveBeleg.');
        }
        if (destinationInhaberId === null || destinationInhaberId === undefined) {
            throw new Error('Required parameter destinationInhaberId was null or undefined when calling moveBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/move/${encodeURIComponent(String(destinationInhaberId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen der Belege
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegFilter Objekt für die Filterung von Belegen.&lt;br&gt; 
     * @param belegPageable Objekt für das OffSet, Limit und die Sortierung beim Laden von Belegen.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBelege(inhaberId: string, belegFilter?: FilterBelegDTO, belegPageable?: PageableBelegDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BelegDTO>>;
    public readBelege(inhaberId: string, belegFilter?: FilterBelegDTO, belegPageable?: PageableBelegDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BelegDTO>>>;
    public readBelege(inhaberId: string, belegFilter?: FilterBelegDTO, belegPageable?: PageableBelegDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BelegDTO>>>;
    public readBelege(inhaberId: string, belegFilter?: FilterBelegDTO, belegPageable?: PageableBelegDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readBelege.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (belegFilter !== undefined && belegFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>belegFilter, 'belegFilter');
        }
        if (belegPageable !== undefined && belegPageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>belegPageable, 'belegPageable');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BelegDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen der aktuellen Importe einer Firma
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readImportStatus(inhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ImportStatusDTO>>;
    public readImportStatus(inhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ImportStatusDTO>>>;
    public readImportStatus(inhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ImportStatusDTO>>>;
    public readImportStatus(inhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readImportStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ImportStatusDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/importstatus`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Stornieren eines Belegs
     * Storniert den Beleg und legt wenn createKorrekturBeleg&#x3D;true einen Korrekturbeleg an. Die UUID des Korrekturbelegs wird zurückgegeben. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param reverseBelegRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reverseBeleg(inhaberId: string, belegId: string, reverseBelegRequestDTO: ReverseBelegRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReverseBelegResultDTO>;
    public reverseBeleg(inhaberId: string, belegId: string, reverseBelegRequestDTO: ReverseBelegRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReverseBelegResultDTO>>;
    public reverseBeleg(inhaberId: string, belegId: string, reverseBelegRequestDTO: ReverseBelegRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReverseBelegResultDTO>>;
    public reverseBeleg(inhaberId: string, belegId: string, reverseBelegRequestDTO: ReverseBelegRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling reverseBeleg.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling reverseBeleg.');
        }
        if (reverseBelegRequestDTO === null || reverseBelegRequestDTO === undefined) {
            throw new Error('Required parameter reverseBelegRequestDTO was null or undefined when calling reverseBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ReverseBelegResultDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/reverse`,
            reverseBelegRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Festschreiben eines Belegs
     * Schreibt einen Beleg fest. Hierbei werden Status und Plfichtfelder Berücksichtigt. Es wird der Status auf \&#39;festgeschrieben\&#39; geändert was den Beleg unveränderlich macht (GoBD). Gleichzeitig wird dem Beleg eine je Inhaber(Betrieb) eindeutige Nummer zugewiesen. Diese muss unveränderlich, eindeutig und lückenlos sein. Festgeschrieben werden können Belege die den Status \&#39;bearbeitet\&#39; haben und bei denen alle Pflichtfelder gefüllt sind. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stipulateBeleg(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StipulateBelegResultDTO>;
    public stipulateBeleg(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StipulateBelegResultDTO>>;
    public stipulateBeleg(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StipulateBelegResultDTO>>;
    public stipulateBeleg(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling stipulateBeleg.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling stipulateBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<StipulateBelegResultDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/stipulate`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisieren eines Belegs
     * Aktualisieren der Metadaten zu einem Beleg. Eigenbelege lassen keine Aktualisierung zu und sind nur über ADNOVA+ zu bearbeiten. Aktualisieren von Eigenbelegen wird mit einem Fehler beantwortet. Über die API ist nicht erkennbar ob es sich bei einem Beleg um einen Eigenbeleg handelt. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param updateBelegRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBeleg(inhaberId: string, belegId: string, updateBelegRequestDTO: UpdateBelegRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateBeleg(inhaberId: string, belegId: string, updateBelegRequestDTO: UpdateBelegRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateBeleg(inhaberId: string, belegId: string, updateBelegRequestDTO: UpdateBelegRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateBeleg(inhaberId: string, belegId: string, updateBelegRequestDTO: UpdateBelegRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateBeleg.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling updateBeleg.');
        }
        if (updateBelegRequestDTO === null || updateBelegRequestDTO === undefined) {
            throw new Error('Required parameter updateBelegRequestDTO was null or undefined when calling updateBeleg.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}`,
            updateBelegRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
