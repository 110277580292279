import {inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {combineLatest, from, take} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {OverviewService} from './overview.service';
import {BelegIdentity} from '../interfaces/beleg-identity.interface';
import {EditPageData} from '../interfaces/edit-page.data.interface';
import {Store} from '@ngrx/store';
import {UtilityWidgetService} from '../modules/widget/utility-widget/utility-widget-service';
import {AppState} from '../store/states/app.state';
import {ZahlungEntitiesActions} from '../store/actions/zahlung-entities.actions';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';
import {BelegEntitiesActions} from '../store/actions/beleg-entities.actions';
import {OverviewTableActions} from '../store/actions/overview-table.actions';
import {OverviewTableSelectors} from '../store/selectors/overview-table.selectors';
import {takeUntil} from 'rxjs/operators';


export interface EditOpenDataV1 {
  readonly inhaberId: string;
  readonly belegId: string;
}

export interface EditZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
  readonly belegId?: string;
}

export interface DeleteZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
}

export interface NewZahlungDataV1 {
  readonly inhaberId: string;
  readonly belegId: string;
}

export interface OverviewOpenDataV1 {
  readonly inhaberId: string;
  readonly filter?: OverviewOpenFilterDataV1;
}

export interface OverviewOpenFilterDataV1 {
  readonly vonBuchstelle?: boolean;
  readonly offen?: boolean;
  readonly bearbeitet?: boolean;
  readonly festgeschrieben?: boolean;
  readonly storniert?: boolean;
  readonly searchText?: string;
}

export interface UpdatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface CreatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface DeletedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface CreatedBelegDataV1 {
  readonly inhaberId: string;
}

export interface UpdatedBelegDataV1 {
  readonly inhaberId: string;
}

export interface DeletedBelegDataV1 {
  readonly inhaberId: string;
}

export interface UpdatedBetriebDataV1 {
  readonly betriebId: string;
}

export interface FakturierungsbelegFestgeschriebenDataV1 {
  readonly betriebId: string;
  readonly fakturierungsbelegId: string;
}

export interface FakturierungsbelegStorniertDataV1 {
  readonly betriebId: string;
  readonly fakturierungsbelegId: string;
}

@Injectable({
  providedIn: 'root',
})
export class IntentActionService {

  private _logger = inject(NGXLogger);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _store = inject(Store<AppState>);
  private _overviewService = inject(OverviewService);
  private _utilityWidgetService = inject(UtilityWidgetService);

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht für einen Beleg
   *
   * JSON Schema: /assets/intents/de.just-farming.belege-online_edit_open_v1.schema.json
   *
   * @private
   */
  private readonly _editOpenIdV1 = 'de.just-farming:belege-online:edit.open';

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht für eine Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_popup_edit_v1.schema.json
   *
   * @private
   */
  private readonly _editZahlungIdV1 = 'de.just-farming:zahlungen:popup.edit';

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht für eine Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_edit_open_v1.schema.json
   *
   * @private
   */
  private readonly _deleteZahlungIdV1 = 'de.just-farming:zahlungen:popup.delete';

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht zum Erstellen einer neuen Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_new_v1.schema.json
   *
   * @private
   */
  private readonly _newZahlungIdV1 = 'de.just-farming:zahlungen:new';

  /**
   * Intent-Id für den Aufruf der Overview-Ansicht
   *
   * JSON Schema: /assets/intents/de.justfarming.belege-online_overview_open_v1.schema.json
   *
   * @private
   */
  private readonly _overviewOpenIdV1 = 'de.just-farming:belege-online:overview.open';

  /**
   * Intent-Id für den Broadcast zur Information über eine aktualisierte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.updated_v1.schema.json
   *
   * @private
   */
  private readonly _updatedZahlungIdV1 = 'de.just-farming:zahlungen:payment.updated';

  /**
   * Intent-Id für den Broadcast zur Information über eine erstellte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.created_v1.schema.json
   *
   * @private
   */
  private readonly _createdZahlungIdV1 = 'de.just-farming:zahlungen:payment.created';

  /**
   * Intent-Id für den Broadcast zur Information über eine gelöschte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.deleted_v1.schema.json
   *
   * @private
   */
  private readonly _deletedZahlungIdV1 = 'de.just-farming:zahlungen:payment.deleted';

  /**
   * Intent-Id für den Broadcast zur Information über einen erstellten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_beleg.created_v1.schema.json
   *
   * @private
   */
  private readonly _belegCreatedIdV1 = 'de.just-farming:belege-online:beleg.created';

  /**
   * Intent-Id für den Broadcast zur Information über einen aktualisierten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_beleg.updated_v1.schema.json
   *
   * @private
   */
  private readonly _belegUpdatedIdV1 = 'de.just-farming:belege-online:beleg.updated';

  /**
   * Intent-Id für den Broadcast zur Information über einen gelöschten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_beleg.deleted_v1.schema.json
   *
   * @private
   */
  private readonly _belegDeletedIdV1 = 'de.just-farming:belege-online:beleg.deleted';

  /**
   * Intent-Id für den Broadcast zur Information über einen aktualisierten Betrieb
   *
   * @private
   */
  private readonly _betriebUpdatedIdV1 = 'de.just-farming:betriebsinformationen:betrieb.updated';

  /**
   * Intent-Id für den Broadcast zur Information über einen festgeschriebenen Fakturierungsbeleg
   *
   * @private
   */
  private readonly _fakturierungsbelegFestgeschriebenIdV1 = 'de.just-farming.fakturierung:fakturierungsbeleg.festgeschrieben';

  /**
   * Intent-Id für den Broadcast zur Information über einen stornierten Fakturierungsbeleg
   *
   * @private
   */
  private readonly _fakturierungsbelegStorniertIdV1 = 'de.just-farming.fakturierung:fakturierungsbeleg.storniert';

  constructor() {
    if (this.isRunningInPortal()) {

      // INFO: Beleg erstellt.
      this._portalMessageBroker.registerIntentCallback(this._belegCreatedIdV1, '1', (data => {
        this.handleBelegCreatedV1(data);
      }));

      // INFO: Beleg aktualisiert.
      this._portalMessageBroker.registerIntentCallback(this._belegUpdatedIdV1, '1', (data => {
        this.handleBelegUpdatedV1(data);
      }));

      // INFO: Beleg gelöscht.
      this._portalMessageBroker.registerIntentCallback(this._belegDeletedIdV1, '1', (data => {
        this.handleBelegDeletedV1(data);
      }));

      // INFO: Öffenen der Bearbeiten-Seite.
      this._portalMessageBroker.registerIntentCallback(this._editOpenIdV1, '1', (data => {
        this.handleEditOpenV1(data);
      }));

      // INFO: Öffenen der Übersicht-Seite.
      this._portalMessageBroker.registerIntentCallback(this._overviewOpenIdV1, '1', (data => {
        this.handleOverviewOpenV1(data);
      }));

      // INFO: Zahlung aktualisiert.
      this._portalMessageBroker.registerIntentCallback(this._updatedZahlungIdV1, '1', (data => {
        this.handlePaymentUpdatedV1(data);
      }));

      // INFO: Zahlung erstellt.
      this._portalMessageBroker.registerIntentCallback(this._createdZahlungIdV1, '1', (data => {
        this.handlePaymentCreatedV1(data);
      }));

      // INFO: Zahlung gelöscht.
      this._portalMessageBroker.registerIntentCallback(this._deletedZahlungIdV1, '1', (data => {
        this.handlePaymentDeletedV1(data);
      }));

      // INFO: Betrieb aktualisiert.
      this._portalMessageBroker.registerIntentCallback(this._betriebUpdatedIdV1, '1', (data => {
        this.handleBetriebUpdatedV1(data);
      }));

      // INFO: Fakturierungsbeleg festgeschrieben.
      this._portalMessageBroker.registerIntentCallback(this._fakturierungsbelegFestgeschriebenIdV1, '1', (data => {
        this.handleFakturierungsbelegFestgeschriebenV1(data);
      }));

      // INFO: Fakturierungsbeleg storniert.
      this._portalMessageBroker.registerIntentCallback(this._fakturierungsbelegStorniertIdV1, '1', (data => {
        this.handleFakturierungsbelegStorniertV1(data);
      }));

      this._portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this._portalMessageBroker.isRunningInPortal();
  }

  /**
   * Öffnet über einen Intent die Editor-Ansicht zu einem Beleg
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doEditOpenV1(
    data: EditOpenDataV1,
  ): void {
    this.doEmit(this._editOpenIdV1, '1', data);
  }

  handleEditOpenV1(
    data: EditOpenDataV1,
  ): void {
    const belege: BelegIdentity[] = [{
      inhaberId: data.inhaberId,
      id: data.belegId,
    }];

    const editPageData: EditPageData = {
      inhaberId: data.inhaberId,
      index: 0,
      belege,
    };

    this._overviewService.openEditBeleg(editPageData);
  }

  /**
   * Öffnet über einen Intent die Editor-Ansicht zu einer Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doEditZahlungV1(
    data: EditZahlungDataV1,
  ): void {
    this.doEmit(this._editZahlungIdV1, '1', data);
  }


  /**
   * Öffnet über einen Intent das Delete-Dialog zu einer Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doDeleteZahlungV1(
    data: DeleteZahlungDataV1,
  ): void {
    this.doEmit(this._deleteZahlungIdV1, '1', data);
  }


  /**
   * Öffnet über einen Intent die Seite zum Erstellen einer neuen Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doNewZahlungV1(
    data: NewZahlungDataV1,
  ): void {
    this.doEmit(this._newZahlungIdV1, '1', data);
  }


  /**
   * Öffnet über einen Intent die Overview-Ansicht
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doOverviewOpenV1(
    data: OverviewOpenDataV1,
  ): void {
    this.doEmit(this._overviewOpenIdV1, '1', data);
  }

  public doCreatedBelegV1(
    data: CreatedBelegDataV1,
  ): void {
    this.doEmit(this._belegCreatedIdV1, '1', data);
  }

  public doUpdatedBelegV1(
    data: UpdatedBelegDataV1,
  ): void {
    this.doEmit(this._belegUpdatedIdV1, '1', data);
  }

  public doDeletedBelegV1(
    data: DeletedBelegDataV1,
  ): void {
    this.doEmit(this._belegDeletedIdV1, '1', data);
  }

  handleBelegCreatedV1(
    data: CreatedBelegDataV1,
  ): void {
    this._utilityWidgetService.readUtilityWidgetData(data.inhaberId);
  }

  handleBelegUpdatedV1(
    data: UpdatedBelegDataV1,
  ): void {
    this._utilityWidgetService.readUtilityWidgetData(data.inhaberId);
  }

  handleBelegDeletedV1(
    data: DeletedBelegDataV1,
  ): void {
    this._utilityWidgetService.readUtilityWidgetData(data.inhaberId);
  }

  handleOverviewOpenV1(
    data: OverviewOpenDataV1,
  ): void {

    this._overviewService.openOverview(
      data.inhaberId,
      data.filter,
      data.filter?.searchText,
    );
  }

  handlePaymentCreatedV1(
    data: CreatedZahlungDataV1,
  ): void {
    this._logger.info('belege online received zahlung created intent', data);

    for (const zahlungId of data.zahlungenIds) {
      this._store.dispatch(ZahlungEntitiesActions.getZahlung({
        inhaberId: data.inhaberId,
        zahlungId,
      }));
    }
  }

  handlePaymentUpdatedV1(
    data: UpdatedZahlungDataV1,
  ): void {
    this._logger.info('belege online received zahlung updated intent', data);

    for (const zahlungId of data.zahlungenIds) {
      this._store.dispatch(ZahlungEntitiesActions.getZahlung({
        inhaberId: data.inhaberId,
        zahlungId,
      }));
    }
  }

  handlePaymentDeletedV1(
    data: DeletedZahlungDataV1,
  ): void {
    this._logger.info('belege online received zahlung deleted intent', data);

    for (const zahlungId of data.zahlungenIds) {
      this._store.dispatch(ZahlungEntitiesActions.deleteZahlungSuccess({
        zahlungId: zahlungId,
      }));
    }
  }

  /**
   * Löst das Aktualisieren der Daten des Betriebs aus.
   *
   * @param data
   */
  handleBetriebUpdatedV1(
    data: UpdatedBetriebDataV1,
  ): void {
    this._logger.debug('belege online received betrieb updated intent for betriebId:', data.betriebId);

    // INFO: Die Daten der Betriebe in der Betriebsauswahl müssen aktualisiert werden.
    this._store.dispatch(InhaberEntitiesActions.loadInhabers());
  }

  /**
   * Löst das Aktualisieren der Belege in der Tabelle aus,
   * nach dem ein Fakturierungsbeleg festgeschrieben worden ist.
   *
   * @param data
   */
  handleFakturierungsbelegFestgeschriebenV1(
    data: FakturierungsbelegFestgeschriebenDataV1,
  ): void {
    this._logger.info('belege online received fakturierungsbeleg festgeschrieben intent', data);
    this.reloadBelegTable(data.betriebId);
  }

  /**
   * Löst das Aktualisieren der Belege in der Tabelle aus,
   * nach dem ein Fakturierungsbeleg storniert worden ist.
   *
   * @param data
   */
  handleFakturierungsbelegStorniertV1(
    data: FakturierungsbelegStorniertDataV1,
  ): void {
    this._logger.info('belege online received fakturierungsbeleg storniert intent', data);
    this.reloadBelegTable(data.betriebId);
  }

  private reloadBelegTable(inhaberId: string): void {
    combineLatest([
      this._store.select(OverviewTableSelectors.filterDto),
      this._store.select(OverviewTableSelectors.pageableDto),
    ]).pipe(
      take(1),
    ).subscribe(([
                   filterDto,
                   pageableDto,
                 ]) => {
      this._store.dispatch(OverviewTableActions.readBelege({
        inhaberId,
        filterDto,
        pageableDto,
      }));

      this._store.dispatch(BelegEntitiesActions.countBelege({
        inhaberId,
        filterDto,
      }));
    });
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this._portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
