import {Injectable} from '@angular/core';
import {BelegDTO, ErkennungsartDTO} from '../openapi/beleg-openapi';


@Injectable({
  providedIn: 'root',
})
export class BelegUtilService {

  private readonly applicationPdf = 'application/pdf';

  constructor() {
  }

  isElectronicInvoice(beleg: BelegDTO): boolean {
    return beleg.erkennungsart === ErkennungsartDTO.Zugferd
      || beleg.erkennungsart === ErkennungsartDTO.XRechnung
      || beleg.erkennungsart === ErkennungsartDTO.FakturierungPortal;
  }

  isFakturierungsbeleg(beleg: BelegDTO): boolean {
    return beleg.erkennungsart === ErkennungsartDTO.Fakturierung
      || beleg.erkennungsart === ErkennungsartDTO.FakturierungPortal;
  }

  isStatusOffenOrBearbeitet(beleg: BelegDTO): boolean {
    return (beleg.offen || beleg.bearbeitet) || false;
  }

  isStatusReversed(beleg: BelegDTO): boolean {
    return beleg.storniert || false;
  }

  isDeletable(beleg: BelegDTO): boolean {
    return !beleg.storniert && !this.isFakturierungsbeleg(beleg);
  }

  isMoveable(beleg: BelegDTO): boolean {
    return this.isStatusOffenOrBearbeitet(beleg) && !this.isFakturierungsbeleg(beleg);
  }

  isSlicable(beleg: BelegDTO): boolean {
    return this.isStatusOffenOrBearbeitet(beleg)
      && beleg.seitenanzahl !== undefined
      && beleg.seitenanzahl > 1
      && beleg.contentType !== undefined
      && beleg.contentType === this.applicationPdf
      && !this.isElectronicInvoice(beleg)
      && !this.isFakturierungsbeleg(beleg);
  }

  isSplicable(beleg: BelegDTO): boolean {
    return this.isStatusOffenOrBearbeitet(beleg)
      && beleg.seitenanzahl !== undefined
      && beleg.contentType !== undefined
      && beleg.contentType === this.applicationPdf
      && !this.isElectronicInvoice(beleg)
      && !this.isFakturierungsbeleg(beleg);
  }

  hasDubletten(beleg: BelegDTO): boolean {
    return (beleg.dubletten && beleg.dubletten.length > 0) || false;
  }
}
