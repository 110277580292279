/**
 * Belege
 * Die Belege aus dem Belegbuch in ADNOVA+ können mittels dieser API bearbeitet, geheftet, entheftet sowie festgeschrieben und storniert werden. Des Weiteren können auch neue Belege importiert werden. Der Import läuft über eine Queue. Daher kann nach dem Aufruf der \"createBeleg\" - Schnittstelle über die Schnittstelle \"importstatus\", mittels der InhaltId, der Status abgefragt werden. Enthält der Importstatus eine BelegId ist der Beleg erfolgreich angelegt worden und kann über diese BelegId abgerufen werden. Damit der Beleg vollständig bearbeitet und ergänzt werden kann, lassen sich die nötigen Daten zu Vorgangsarten, Stichworte, Label, Ordner, Erläuterungen und FreitextPartner abrufen und bearbeiten. Das Heften und Entheften von Belegen ist ebenfalls möglich,. Beim Entheften ist darauf zu achten, dass die einzelnen Seiten wie neue Belege importiert werden. Daher müssen über den Importstatus die neuen BelegIds abgefragt werden, wenn der Beleg fertig entheftet ist. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BelegAktionDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BelegAktionenService {

    protected basePath = 'https://api.adnova.de/beleg-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Lesen der Aktionen zu einem Beleg
     * Zu einem Beleg kann es Aktionen geben, die asynchron ausgeführt werden. Dieser Endpunkt gibt die Aktionen für diesen Beleg aus. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param belegId Id die einen Beleg identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBelegAktionen(inhaberId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BelegAktionDTO>>;
    public readBelegAktionen(inhaberId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BelegAktionDTO>>>;
    public readBelegAktionen(inhaberId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BelegAktionDTO>>>;
    public readBelegAktionen(inhaberId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readBelegAktionen.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling readBelegAktionen.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BelegAktionDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/belege/${encodeURIComponent(String(belegId))}/aktionen`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
