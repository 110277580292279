<ng-container *ngIf="loading">
  <div class="spinner-wrapper d-flex justify-content-center align-items-center">
    <mat-spinner [diameter]="50"
                 [color]="'accent'"
    ></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <ngx-extended-pdf-viewer
    *ngIf="blob"
    [filenameForDownload]="beleg?.dateiname || 'unbekannt.pdf'"
    [src]="blob"
    [useBrowserLocale]="true"
    [ignoreKeyboard]="true"
    [handTool]="false"
    [sidebarVisible]="false"
    [textLayer]="true"
    [zoom]="zoomSetting || defaultZoom"
    [customToolbar]="multiToolbar"
    [(page)]="currentPage"
    (pagesLoaded)="onPagesLoaded($event)"
    (textLayerRendered)="highlightWords($event)"
    #pdfViewer
  >
    <ng-template #multiToolbar>
      <div class="pdf-header">

        <div class="left-bar">
          <mat-form-field appearance="outline" class="search">
            <mat-label>
              <div class="searchfield-content-wrapper">
                <bo-icon icon="common:search" primaryStroke="#999999"></bo-icon>
                Inhalt durchsuchen
              </div>
            </mat-label>
            <input matInput type="text" [formControl]="search" autocomplete="off" class="searchfield">
          </mat-form-field>

          <button mat-icon-button id="findPrevious">
            <bo-icon icon="common:circumflex_left" primaryFill="#999"></bo-icon>
          </button>

          <button mat-icon-button id="findNext">
            <bo-icon icon="common:circumflex_right" primaryFill="#999"></bo-icon>
          </button>

          <mat-form-field appearance="outline" class="page-number">
            <mat-label>Seite</mat-label>
            <input matInput
                   type="text"
                   [formControl]="page"
                   autocomplete="off"
                   [value]="currentPage.toString(10)"
            >
          </mat-form-field>

          <span class="off-pages">
            von {{pagesCount}}
          </span>
        </div>


        <div class="center-bar">
          <mat-form-field appearance="outline" class="zoom-selector">
            <mat-label>Zoom</mat-label>
            <div class="option-wrapper">
              <mat-select [formControl]="zoom">
                <mat-option value="auto">Automatisch</mat-option>
                <mat-option value="page-actual">Echte Größe</mat-option>
                <mat-option value="page-fit">Seite</mat-option>
                <mat-option value="page-width">Breite</mat-option>
                <mat-option value="25">25%</mat-option>
                <mat-option value="50">50%</mat-option>
                <mat-option value="75">75%</mat-option>
                <mat-option value="100">100%</mat-option>
                <mat-option value="125">125%</mat-option>
                <mat-option value="150">150%</mat-option>
                <mat-option value="175">175%</mat-option>
                <mat-option value="200">200%</mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>

        <div class="right-bar">
          <button mat-icon-button id="pageRotateCw">
            <bo-icon icon="common:rotate_right" primaryStroke="#999"></bo-icon>
          </button>
          <button mat-icon-button id="print">
            <bo-icon icon="common:print" primaryStroke="#999"></bo-icon>
          </button>
          <button mat-icon-button id="download">
            <bo-icon icon="common:download_pdf_viewer" primaryStroke="#999" primaryFill="#999"></bo-icon>
          </button>
        </div>

        <div class="pdf-type">
          <jf-icon  *ngIf="eRechnung" [name]="'eRechnung'" [size]="24" ></jf-icon>
          <jf-icon *ngIf="!eRechnung" [name]="'pdfFileAlt'" [size]="24"></jf-icon>
        </div>

      </div>
    </ng-template>

  </ngx-extended-pdf-viewer>
</ng-container>
