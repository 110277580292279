import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BelegService } from './api/beleg.service';
import { BelegAktionenService } from './api/beleg-aktionen.service';
import { BelegContentInternalService } from './api/beleg-content-internal.service';
import { BelegFakturierungInternalService } from './api/beleg-fakturierung-internal.service';
import { BelegInternalService } from './api/beleg-internal.service';
import { ErlaeuterungService } from './api/erlaeuterung.service';
import { FreitextPartnerService } from './api/freitext-partner.service';
import { InhaberService } from './api/inhaber.service';
import { LabelService } from './api/label.service';
import { OidcClientsService } from './api/oidc-clients.service';
import { OrdnerService } from './api/ordner.service';
import { StichwortService } from './api/stichwort.service';
import { VorgangsartService } from './api/vorgangsart.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
