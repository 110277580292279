import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NGXLogger} from 'ngx-logger';
import {Preview} from '../preview';
import {BelegDownloadService} from '../../beleg-api/beleg-download.service';
import {
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerService,
  PagesLoadedEvent,
  TextLayerRenderedEvent,
} from 'ngx-extended-pdf-viewer';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';


@Component({
  selector: 'bo-preview-pdf',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent extends Preview implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('pdfViewer')
  public pdfViewer?: NgxExtendedPdfViewerComponent;

  public search = new FormControl();

  public page = new FormControl();

  public currentPage = 1;

  public pagesCount = 1;

  public zoom = new FormControl();

  public zoomSetting?: string;

  public defaultZoom = 'page-fit';

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(
    logger: NGXLogger,
    snackBar: MatSnackBar,
    belegDownloadService: BelegDownloadService,
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
  ) {
    super(logger, snackBar, belegDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // INFO: Wenn sich der Begriff in der Searchbar ändert, werden alle gefundenen Ergebnisse gehighlighted
    this.search.valueChanges
      .pipe(
        debounceTime(200),
      ).subscribe((value: string) => {
        this.ngxExtendedPdfViewerService.find(value, {
          highlightAll: false,
          matchCase: false,
          matchDiacritics: false,
        });
      }
    );

    // INFO: Standard-Zoom
    this.zoom.setValue(this.defaultZoom);

    this.zoom.valueChanges
      .pipe(
        debounceTime(200),
      ).subscribe((value: string) => {
        this.zoomSetting = value;
      }
    );


    // INFO: Standard-Page
    this.page.setValue(1);

    this.page.valueChanges
      .pipe(
        debounceTime(200),
      ).subscribe((value: number) => {
        this.currentPage = value;
      }
    );
  }

  ngAfterViewInit(): void {
    this.blobChanged$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(() => {
      if (!this.pdfViewer) return;

      this.pdfViewer.pageRender.subscribe(renderEvent => {
        const scaleFactor = 2;
        this.adjustCanvasResolution(renderEvent, scaleFactor);
      });

    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onPagesLoaded(event: PagesLoadedEvent) {
    this.pagesCount = event.pagesCount;
  }

  /**
   * Der folgende Code erhöht die Render-Auflösung des Canvas, um die Darstellungsqualität der PDFs im
   * ngx-extended-pdf-viewer zu verbessern. Dabei werden die Höhe und Breite des Canvas vergrößert und der
   * Canvas-Kontext entsprechend skaliert, um die Größe des gerenderten Bereichs beizubehalten. Dadurch wird der
   * gerenderte Inhalt des PDFs weniger pixelig und schärfer dargestellt, insbesondere bei niedrigen Zoomstufen.
   *
   * @param renderEvent - Das RenderEvent-Objekt, das Informationen über das zu rendernde PDF enthält.
   * @param scaleFactor - Der Faktor, mit dem die Canvas-Auflösung erhöht werden soll.
   */
  private adjustCanvasResolution(renderEvent: any, scaleFactor: number): void {
    const renderSource = renderEvent.source;
    const canvas = renderSource.canvas;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Erhöhen der Auflösung des Canvas
    canvas.width *= scaleFactor;
    canvas.height *= scaleFactor;

    // Skalieren des Kontexts entsprechend
    ctx.scale(scaleFactor, scaleFactor);
  }


  /* Es folgen Methoden zum Highlighten von Wörtern in der PDF Preview.
  * Diese stammen aus folgender Quelle und wurden leicht angepasst:
  * https://pdfviewer.net/extended-pdf-viewer/textlayer
  * */

  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match, @typescript-eslint/member-ordering */
  private alreadyRendered: Array<HTMLSpanElement> = [];
  private _markWords = false;
  private _wordsToMark: string[] = [];

  /* eslint-enable */

  public set wordsToMark(words: string[]) {
    // Array leeren (nicht durch durch ein neues Array überschreiben, um Referenz zu erhalten) ...
    this._wordsToMark.splice(0, this._wordsToMark.length);

    // ... und neu befüllen
    for (const word of words) {
      this._wordsToMark.push(word);
    }
  }

  public get markWords(): boolean {
    return this._markWords;
  }

  public set markWords(mark: boolean) {
    this._markWords = mark;
    this.alreadyRendered.forEach((span) => this.doMarkWordsInSpan(span));
  }

  public doMarkWordsInSpan(span: HTMLSpanElement): void {
    // Teil-Wort entfernen, sodass im innerText nur noch das eigentliche Wort steht
    const highlighted = span.getElementsByClassName('highlighted-word');
    for (let i = 0; highlighted.length >= i; i++) {
      const highlightedItem = highlighted.item(0);
      if (highlightedItem) {
        highlightedItem.remove();
      }
    }

    const before = span.innerText;
    let after;
    if (!this._markWords) {
      after = before.replace('\n', '');
    } else {
      after = before
        .split(' ')
        .map((t) => this.markOneWord(t))
        .join(' ');
    }
    if (before !== after) {
      span.innerHTML = after;
    }
  }

  private markOneWord(word: string): string {
    const wordLowerCase = word.toLowerCase();
    for (const wordToMark of this._wordsToMark) {
      if (wordLowerCase.startsWith(wordToMark.toLowerCase())) {
        // Highlight des Teil-Wortes einfügen und
        // eigentliches Wort ausblenden (nicht löschen, da es oben mit innerText wieder eingelesen werden muss)
        return `<div class="highlighted-word">${wordToMark}<div class="highlight-filter"></div></div><div class="placeholderDiv">${word}</div>`;
      }
    }
    return word;
  }

  public highlightWords(event: TextLayerRenderedEvent): void {
    // info: Dieser Code wird aktuell im Beispiel gezeigt
    // siehe https://pdfviewer.net/extended-pdf-viewer/textlayer
    let textDivs = event.source.textLayer?.textDivs;

    // info: Jedoch sind die gewünschten Werte nur hier verfügbar.
    // Da nicht klar ist, ob es sich um eine gewollte Änderung oder einen Bug handelt,
    // werden hier vorerst beide wege implementiert.
    if (!textDivs) {
      const source = event.source as any;
      const textLayer = source.textLayer as any;
      textDivs = textLayer.textDivs as HTMLSpanElement[];
    }
    if (!textDivs) {
      this.logger.error('Could not highlight word, \'textDivs\' missing');
      textDivs = [];
    }

    textDivs.forEach((span: any) => {
      this.alreadyRendered.push(span);
    });

    if (this._markWords) {
      textDivs.forEach((span: any) => {
        this.doMarkWordsInSpan(span);
      });
    }
  }
}
