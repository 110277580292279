<mat-form-field appearance="outline">
  <mat-label>Status</mat-label>
  <mat-select (selectionChange)="this.statusFilter.next($event)" multiple>
    <div
      (mouseenter)="cancelCloseOptions()"
      (mouseleave)="closeOptions()"
    >
      <mat-option
        *ngFor="let status of statusList"
        [value]="status"
      >
        <jf-status
          [status]="status.icon"
          [label]="status.text"
        ></jf-status>
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
