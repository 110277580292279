import {HttpEventType, HttpResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NGXLogger} from 'ngx-logger';
import {EMPTY, Observable, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import {BelegDTO, ErkennungsartDTO} from '../../../openapi/beleg-openapi';
import {BelegDownloadService} from '../beleg-api/beleg-download.service';


@Component({
  selector: 'bo-preview',
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Preview implements OnInit, OnDestroy {

  protected readonly unsubscribe$ = new Subject<void>();

  @Input()
  beleg$ = new Observable<BelegDTO | undefined>();

  @Output()
  closePreview = new EventEmitter();

  inhaberId!: string;

  loading = false;

  blob: Blob | string = '';

  blobChanged$ = new Subject<void>();

  blobUrl: string | null = null;

  beleg?: BelegDTO;

  belegSubscription?: Subscription;

  belegPipedSubscription?: Subscription;

  eRechnung = false;

  constructor(
    protected logger: NGXLogger,
    private snackBar: MatSnackBar,
    private belegDownloadService: BelegDownloadService,
  ) { }

  doClosePreview(): void {
    this.beleg = undefined;
    this.closePreview.emit({value: this});
  }

  ngOnInit(): void {
    this.belegSubscription = this.beleg$.subscribe(
      value => {
        this.loading = true;
        if (value === undefined) {
          this.beleg = undefined;
        } else {
          this.beleg = value;
          this.inhaberId = value.inhaberId;
          this.eRechnung = this.isERechnung(value);
        }
      }
    );
    this.belegPipedSubscription = this.beleg$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(
          value => {
            this.blob = '';
            this.blobUrl = '';
          }),
        debounceTime(250),
        distinctUntilChanged(),
        filter(value => {
          if (value) {
            return true;
          }
          return false;
        }),
        map(value => value as BelegDTO),
        switchMap(beleg => this.belegDownloadService.backgroundDownload(beleg)),
        catchError(
          error => {
            this.logger.warn('error loading content', error);
            this.snackBar.open('Belege konnten nicht geladen werden', undefined, {duration: 5000, panelClass: 'error'});

            return EMPTY;
          }
        ),
        filter(value => value.type === HttpEventType.Response),
        map(value => value as HttpResponse<Blob>),
        map(value => value.body as Blob)
      )
      .subscribe(
        blob => {
          this.loading = false;
          if (this.blobUrl) {
            window.URL.revokeObjectURL(this.blobUrl);
          }
          if (blob instanceof Blob) {
            this.blob = blob;
            this.blobChanged$.next();
            this.blobUrl = window.URL.createObjectURL(this.blob);
          }
        },
        error => {
          // TODO error handling
          this.logger.warn('error', error);
        },
        () => {
          this.logger.debug('complete');
        }
      );
  }

  isERechnung(beleg?: BelegDTO): boolean {
    return beleg?.erkennungsart === ErkennungsartDTO.Zugferd || beleg?.erkennungsart === ErkennungsartDTO.XRechnung || beleg?.erkennungsart === ErkennungsartDTO.FakturierungPortal;
  }

  doDownload(beleg?: BelegDTO): boolean {
    if (!beleg) {
      return false;
    }
    if (this.blobUrl && this.blobUrl.length > 0) {
      const a = document.createElement('a');
      a.href = this.blobUrl;
      a.download = beleg.dateiname as string;
      a.click();
    } else {
      this.belegDownloadService.doDownload(beleg);
    }

    return false;
  }

  ngOnDestroy(): void {
    this.belegSubscription?.unsubscribe();
    this.belegPipedSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.doClosePreview();
  }
}
