/**
 * Belege
 * Die Belege aus dem Belegbuch in ADNOVA+ können mittels dieser API bearbeitet, geheftet, entheftet sowie festgeschrieben und storniert werden. Des Weiteren können auch neue Belege importiert werden. Der Import läuft über eine Queue. Daher kann nach dem Aufruf der \"createBeleg\" - Schnittstelle über die Schnittstelle \"importstatus\", mittels der InhaltId, der Status abgefragt werden. Enthält der Importstatus eine BelegId ist der Beleg erfolgreich angelegt worden und kann über diese BelegId abgerufen werden. Damit der Beleg vollständig bearbeitet und ergänzt werden kann, lassen sich die nötigen Daten zu Vorgangsarten, Stichworte, Label, Ordner, Erläuterungen und FreitextPartner abrufen und bearbeiten. Das Heften und Entheften von Belegen ist ebenfalls möglich,. Beim Entheften ist darauf zu achten, dass die einzelnen Seiten wie neue Belege importiert werden. Daher müssen über den Importstatus die neuen BelegIds abgefragt werden, wenn der Beleg fertig entheftet ist. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Gibt an mit welcher Art die Vorschlagswerte für den Beleg ermittelt wurden. 
 */
export enum ErkennungsartDTO {
    Keine = 'Keine',
    Wiedererkennung = 'Wiedererkennung',
    VollText = 'VollText',
    Zugferd = 'Zugferd',
    QrCode = 'QrCode',
    Outlook = 'Outlook',
    Fakturierung = 'Fakturierung',
    XRechnung = 'XRechnung',
    FakturierungPortal = 'FakturierungPortal'
};

