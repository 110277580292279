// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// Material
import {MatIconModule} from '@angular/material/icon';
// PDF Viewer
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
// Preview
import {PdfPreviewComponent} from './pdf-preview/pdf-preview.component';
import {PreviewComponent} from './preview.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {DownloadPreviewComponent} from './download-preview/download-preview.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {BelegApiModule} from '../beleg-api/beleg-api.module';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {IconModule} from '../icon/icon.module';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {IconComponent} from '@adnova/jf-ng-components';


@NgModule({
  declarations: [
    PdfPreviewComponent,
    PreviewComponent,
    DownloadPreviewComponent
  ],
  imports: [
    // Angular
    CommonModule,
    IconModule,
    // Material
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatSnackBarModule,
    MatSelectModule,
    // PDF Viewer
    NgxExtendedPdfViewerModule,
    BelegApiModule,
    MatProgressSpinnerModule,
    IconComponent,
  ],
  exports: [
    PreviewComponent
  ]
})
export class PreviewModule {
}
