import {SpliceState} from '../states/splice.state';
import {createReducer, on} from '@ngrx/store';
import {SpliceActions} from '../actions/splice.actions';
import {ErkennungsartDTO} from '../../openapi/beleg-openapi';


export const initialSpliceState: SpliceState = {
  filterDto: {
    contentType: 'application/pdf',
    offen: true,
    bearbeitet: true,
    erkennungsart: [
      ErkennungsartDTO.VollText.toString(),
      ErkennungsartDTO.Wiedererkennung.toString(),
      ErkennungsartDTO.Outlook.toString(),
      ErkennungsartDTO.QrCode.toString(),
      ErkennungsartDTO.Keine.toString(),
    ],
  },
  pageableDto: {
    limit: 50,
    offset: 0,
  },
  belegIdsToSplice: [],
  displayedIds: [],
  isSplicing: false,
  selectionFocusInToSpliceTable: false,
  pdfPreviewId: undefined,
};

/*
 * INFO: "searchtext" wird benötigt, um herauszufinden, ob die Liste vor dem Anzeigen einmal geleert werden
 * soll. Wird die Liste nämlich mit Werten gefüllt, nachdem eine neue Suchanfrage gestartet wurde, müssen
 * die alten Werte entfernt werden. Dies ließe sich zwar auch direkt über den Reducer lösen, der auf
 * SpliceActions.setSearchText lauscht, jedoch wäre die Liste dann während der Dauer des Ladevorganges komplett
 * leer und würde erst dann neu gefüllt werden. Das würde beim User ein "blinken" verursachen, welches
 * so verhindert wird.
 */
let searchText = initialSpliceState.filterDto.textfilter?.join(' ');

export const spliceReducer = createReducer(
  initialSpliceState,

  on(
    SpliceActions.setSpliceList,
    (state, {belegIdsToSplice}) => ({
      ...state,
      belegIdsToSplice,
      selectionFocusInToSpliceTable: true,
      pdfPreviewId: belegIdsToSplice.at(0),
    }),
  ),

  on(
    SpliceActions.addToSpliceList,
    (state, {belegIdsToSplice}) => ({
      ...state,
      belegIdsToSplice: [
        ...state.belegIdsToSplice,
        ...belegIdsToSplice,
      ],
    }),
  ),

  on(
    SpliceActions.removeFromSpliceList,
    (state, {belegIdsToRemove}) => ({
      ...state,
      belegIdsToSplice: [
        ...state.belegIdsToSplice.filter(id => !belegIdsToRemove.includes(id)),
      ],
    }),
  ),

  on(
    SpliceActions.setReadBelegePayload,
    (state, {pageableDto, filterDto}) => ({
      ...state,
      filterDto,
      pageableDto,
    }),
  ),

  /*
   * INFO: Da die Liste eine infinite-Scroll-Funktion hat, wird die Liste stets erweitert statt ersetzt.
   * Durch das Einfügen in ein Set werden automatisch Dopplungen entfernt.
   * Anschließend wird es wieder in ein Array umgewandelt.
   */
  on(
    SpliceActions.setDisplayedIds,
    (state, {belegDtos}) => {
      const newSearchText = state.filterDto.textfilter?.join(' ');
      const resultState = {
        ...state,
        displayedIds: [
          ...new Set([
            ...(searchText === newSearchText ? state.displayedIds : []),
            ...belegDtos.map(belegDto => belegDto.id)
          ])
        ],
      };

      searchText = state.filterDto.textfilter?.join(' ');
      return resultState;
    },
  ),

  on(
    SpliceActions.setReadBelegePaginationOffset,
    (state, {offset}) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        offset,
      }
    }),
  ),

  on(
    SpliceActions.setPdfPreviewId,
    (state, {belegId}) => ({
      ...state,
      pdfPreviewId: belegId,
    }),
  ),

  on(
    SpliceActions.reset,
    (state) => ({
      ...initialSpliceState,
    }),
  ),

  on(
    SpliceActions.splice,
    (state) => ({
      ...state,
      isSplicing: true,
    }),
  ),

  on(
    SpliceActions.spliceSuccess,
    (state, {heftenResponseDTO, oldIds}) => ({
      ...state,
      isSplicing: false,
      belegIdsToSplice: [],
      pdfPreviewId: heftenResponseDTO.id,
      selectionFocusInToSpliceTable: false,
      displayedIds: [
        ...state.displayedIds.filter(
          id => !oldIds.filter(oldId => oldId !== heftenResponseDTO.id).includes(id)
        ),
      ],
    }),
  ),

  on(
    SpliceActions.spliceFailure,
    (state) => ({
      ...state,
      isSplicing: false,
    }),
  ),

  on(
    SpliceActions.setSelectionFocus,
    (state, {inToSpliceTable}) => ({
      ...state,
      selectionFocusInToSpliceTable: inToSpliceTable,
    }),
  ),

  on(
    SpliceActions.setSearchText,
    (state, {searchText}) => ({
      ...state,
      filterDto: {
        ...state.filterDto,
        textfilter: searchText.split(' '),
      },
      pageableDto: {
        ...initialSpliceState.pageableDto,
      },
    }),
  ),

  on(
    SpliceActions.moveUp,
    (state, {belegId}) => {
      const index = state.belegIdsToSplice.indexOf(belegId);
      if (index > 0) {
        const newIds = [...state.belegIdsToSplice];
        const temp = newIds[index];
        newIds[index] = newIds[index - 1];
        newIds[index - 1] = temp;
        return {
          ...state,
          belegIdsToSplice: newIds
        };
      }
      return state;
    }
  ),

  on(
    SpliceActions.moveDown,
    (state, {belegId}) => {
      const index = state.belegIdsToSplice.indexOf(belegId);
      if (index > -1 && index < state.belegIdsToSplice.length - 1) {
        const newIds = [...state.belegIdsToSplice];
        const temp = newIds[index];
        newIds[index] = newIds[index + 1];
        newIds[index + 1] = temp;
        return {
          ...state,
          belegIdsToSplice: newIds
        };
      }
      return state;
    }
  ),

  on(
    SpliceActions.remove,
    (state, {belegId}) => ({
      ...state,
      belegIdsToSplice: state.belegIdsToSplice.filter(id => id !== belegId)
    })
  ),

);
