import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  BelegbucheinAusgangDTO,
  BelegDTO, ErkennungsartDTO,
  InhaberDTO,
  PageableBelegDTOSortingEnum,
  VorgangsartDTO,
} from '../../../openapi/beleg-openapi';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {MatMultiSort, MatMultiSortTableDataSource, TableData} from 'ngx-mat-multi-sort';
import {EditPageData} from '../../../interfaces/edit-page.data.interface';
import {NGXLogger} from 'ngx-logger';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {Store} from '@ngrx/store';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {OverviewTableActions} from '../../../store/actions/overview-table.actions';
import {AppState} from '../../../store/states/app.state';
import {BelegEntitiesSelectors} from '../../../store/selectors/beleg-entities.selectors';
import {BelegEntitiesActions} from '../../../store/actions/beleg-entities.actions';
import {StatusFilter} from '../../../interfaces/status-filter.interface';
import {OverviewTableSelectors} from '../../../store/selectors/overview-table.selectors';
import {DeleteDialogActions} from '../../../store/actions/delete-dialog.actions';
import {SpliceActions} from '../../../store/actions/splice.actions';
import {Router} from '@angular/router';
import {DubletteActions} from '../../../store/actions/dublette.actions';
import {ReuseBelegData} from '../../../interfaces/reuse-beleg-data.interface';
import {BelegStipulatedDialogActions} from '../../../store/actions/beleg-stipulated-dialog.actions';
import {MoveBelegDialogActions} from '../../../store/actions/move-beleg-dialog.actions';
import {TableSettings} from '../../../interfaces/table-settings.interface';
import {initialOverviewTableState} from '../../../store/reducers/overview-table.reducer';
import {StipulateDialogActions} from '../../../store/actions/stipulate-dialog.actions';
import {MatExpansionPanel} from '@angular/material/expansion';
import {DateFilterData} from '../../../interfaces/date-filter-data.interface';
import {BelegmailStatus} from '../../../services/belegmail.service';
import {ChipInput, NavigationService} from '@adnova/jf-ng-components';
import {BelegUtilService} from '../../../services/beleg-util-service';
import {Status} from '@adnova/jf-ng-components/lib/generic/indicators/status/status.types';


export enum BelegListColumn {
  Checkbox = 'checkbox',
  Status = 'status',
  Erstellt = 'erstellt',
  Belegtyp = 'vorgangsart.bezeichnung',
  BelegbucheinAusgang = 'belegbucheinAusgang',
  Kommentar = 'kommentar',
  Buchstelle = 'vonBuchstelle',
  Partner = 'partner.bezeichnung',
  Datum = 'belegDatum',
  Betrag = 'betrag',
  Seitenzahl = 'seitenzahl',
  Aktion = 'aktion',
}

@Component({
  selector: 'bo-belege-list',
  templateUrl: './belege-list.component.html',
  styleUrls: ['./belege-list.component.scss'],
})
export class BelegeListComponent implements OnInit, OnChanges, OnDestroy {

  private settings?: Element;

  private readonly unsubscribe$ = new Subject<void>();

  private readonly applicationPdf = 'application/pdf';

  private selectedIds$ = new BehaviorSubject<string[]>([]);

  private tableColumns = initialOverviewTableState.tableSettings._columns;

  private tableSettingsDefault: TableSettings = initialOverviewTableState.tableSettings;

  private filterVisible$ = this.store.select(
    OverviewTableSelectors.filterVisible,
  );

  // INFO: Initial mit Dummy-TableData setzen, um ggf. Restrictions zu umgehen.
  protected table?: TableData<BelegDTO>;

  protected BelegListColumn = BelegListColumn;

  protected moveDeleteDisabled = true;

  protected stipulateDisabled = true;

  protected sliceDisabled = true;

  protected spliceDisabled = true;

  protected belege: BelegDTO[] = [];

  protected pageSize = 0;

  protected pageIndex = 0;

  protected searchValue = '';

  protected statusFilter: StatusFilter = {
    offen: false,
    bearbeitet: false,
    festgeschrieben: false,
    storniert: false,
  };

  protected countedBelege = 0;

  protected selectedBelege$ = new BehaviorSubject<BelegDTO[]>([]);

  protected areAllIdsSelected$ = new BehaviorSubject(false);

  protected isMasterIndeterminate$ = combineLatest([
    this.selectedIds$,
    this.areAllIdsSelected$,
  ]).pipe(
    takeUntil(this.unsubscribe$),
    map(([selectedIds, areAllIdsSelected]) => !areAllIdsSelected && !!selectedIds.length),
  );

  protected moveDestinationInhaber: InhaberDTO[] = [];

  protected inhaberId?: string;

  protected eingangAusgangValue: BelegbucheinAusgangDTO[] = [];

  protected filterVisible = false;

  protected vorgangsartDtos: VorgangsartDTO[] = [];

  protected belegDateValue: DateFilterData = {};

  protected createdDateValue: DateFilterData = {};

  protected showDubletteValue?: boolean;

  protected betragVon?: number;

  protected betragBis?: number;

  protected belegTypValue: string[] = [];

  protected filterCounter = 0;

  protected filterChips: ChipInput[] = [];

  protected readonly BelegmailStatus = BelegmailStatus;

  protected readonly ErkennungsartDTO = ErkennungsartDTO;

  @ViewChild('lastTableCol')
  public lastTableCol!: any;

  @ViewChild('tableContainer')
  public tableContainer: ElementRef | undefined;

  @ViewChild(MatExpansionPanel)
  expansionPanel?: MatExpansionPanel;

  @ViewChild(MatMultiSort, {static: false})
  public matMultiSortTable: MatMultiSort | undefined;

  @Input()
  sort = [PageableBelegDTOSortingEnum.Erstelltdesc];

  @Output()
  openEditBeleg = new EventEmitter<EditPageData>();

  constructor(
    private dialog: MatDialog,
    private logger: NGXLogger,
    private elementRef: ElementRef,
    private store: Store<AppState>,
    private router: Router,
    private readonly tracker: MatomoTracker,
    private navigationService: NavigationService,
    private belegUtilService: BelegUtilService,
  ) {
  }

  /*
   * INFO: Das Einstellungsrad befindet sich normalerweise oberhalb der Tabelle. Es gibt aktuell noch keine Möglichkeit,
   * per Parameter in der Tabellen-Komponente einzustellen, dass es im Tabellenheader angezeigt werden soll.
   *
   * Es gibt daher zwei Wege, das Einstellungsrad in den Tabellenheader zu schieben:
   *
   * 1. Via CSS: Mit absoluter Positionierung und einem hohen z-index das Icon an die Stelle schieben. Problem:
   * Wenn Scrollbars auftreten oder sich die Position und Größer der Tabelle etwas ändern, ist es sehr aufwändig bis
   * unmöglich, das Icon so anzupassen, dass es sich weiterhin an die richtige Stelle in der Tabelle anfügt.
   *
   * 2. Via DOM Manipulation: Wir verschieben einfach per TS das HTML Element an die gewünschte stelle. Problem:
   * Sobald die Tabelle, bzw. die Tabellendaten neu gerendert werden, setzt sich die Position des Elements wieder zurück
   * und wir müssen das Element erneut verschieben.
   */
  moveSettingsGear(): void {
    // INFO: Erst verschieben, wenn die Tabelle geladen wurde. Vorher existiert das Ziel-Element (Tabellenheader) nicht.
    if (this.tableContainer) {

      // INFO: Das Settings-Element wird in einer Variable gespeichert, damit auf dieselbe Instanz referenziert wird.
      if (!this.settings) {
        this.settings = (this.elementRef.nativeElement.getElementsByTagName('mat-multi-sort-table-settings') as HTMLCollection).item(0) || undefined;
      }

      /*
       * INFO: Die Methode soll aufgerufen werden, sobald sich die Daten oder die Spalteneinstellungen ändern. Problem:
       * Wenn wir auf die Spaltenänderungen lauschen, wird die Methode immer vor dem Neu-Rendern der Tabelle ausgeführt.
       * Damit das Verschieben funktioniert, muss sie jedoch im Anschluss ausgelöst werden. Daher wird hier ein Timeout
       * verwendet, der das sicherstellt.
       */
      setTimeout(() => {
        /*
         * INFO: Zu diesem Zeitpunkt befindet sich das Icon nicht mehr im Tabellenheader, da die Tabelle neu gerendert
         * wurde. Es kann aber nicht sicher gesagt werden, wann der Tabellenheader bereit ist. Dafür stellt die
         * Komponente leider auch keinen Observer zur Verfügung. Daher wird hier in einem kurzen Interval solange
         * versucht das Element an die gewünschte Position zu setzen, bis es in der nächsten Iteration gefunden wurde.
         */
        const interval = setInterval(() => {
          let column = this.lastTableCol;
          if (!!column) {
            const lastTableCol = column.nativeElement;
            const settingsInLastCol = lastTableCol.getElementsByTagName('mat-multi-sort-table-settings').length;
            if (settingsInLastCol > 0) {
              clearInterval(interval);
            } else if (this.settings && lastTableCol) {
              lastTableCol.appendChild(this.settings);
            }
          } else {
            this.logger.warn('lastTableCol was unexpectedly undefined.');
          }
        }, 10);
      }, 0);
    }
  }

  ngOnInit(): void {
    // INFO: Zuerst die aktuellen Daten aus dem LocalStorage in den Store übertragen.
    this.readTableSettingsFromLS();

    this.filterVisible$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isVisible => {
      if (isVisible) {
        this.filterVisible = true;
        this.expansionPanel?.open();
      } else {
        this.filterVisible = false;
        this.expansionPanel?.close();
      }
    });

    this.store.select(OverviewTableSelectors.filterCounter).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(counter => {
      this.filterCounter = counter;
    });

    // INFO: Nun die Daten aus dem Store nehmen und damit die Tabellenkonfiguration setzen.
    this.store.select(OverviewTableSelectors.tableSettings).pipe(
      take(1),
    ).subscribe(observedTableSettings => {

      /*
       * INFO: Die Spaltenkonfiguration aus dem Store in die Tabelle übertragen.
       * Dabei eine Kopie erstellen, damit die Arrays NICHT readonly sind.
       */
      const tableSettings: TableSettings = {
        ...observedTableSettings,
        _sortDirs: [
          ...observedTableSettings._sortDirs,
        ],
        _sortParams: [
          ...observedTableSettings._sortParams,
        ],
      };

      /*
       * INFO: Tabelle initialisieren.
       * Versuchen, die Spaltenkonfiguration aus dem LocalStorage zu lesen und zu übernehmen.
       * Bei Fehlschlag wird die Tabelle mit den Standardwerten initialisiert.
       */
      try {
        /*
         * INFO: Wenn nach einer Spalte sortiert wird, die gerade ausgeblendet ist,
         * wird diese aus den Sort-Arrays entfernt, da es sonst zu einem Fehler kommt.
         */
        const columns = tableSettings._columns;
        for (const column of columns) {
          if (!column.isActive) {
            const index = tableSettings._sortParams.indexOf(column.id);
            if (index > -1) {
              tableSettings._sortParams.splice(index, 1);
              tableSettings._sortDirs.splice(index, 1);
            }
          }
        }

        this.table = new TableData<BelegDTO>(
          structuredClone(tableSettings._columns),
          {
            defaultSortParams: tableSettings._sortParams,
            defaultSortDirs: tableSettings._sortDirs,
            localStorageKey: tableSettings._key,
          },
        );
      } catch (error) {
        this.logger.warn('Could not instantiate Table with localStorageKey. Using default values instead.', error);
        this.table = new TableData<BelegDTO>(
          tableSettings._columns,
        );
      }

      // INFO: Tabelle mit der Komponente aus dem HTML DOM verknüpfen.
      setTimeout(() => {
        if (this.table && this.matMultiSortTable) {
          this.table.dataSource = new MatMultiSortTableDataSource(this.matMultiSortTable, false);
        }
      }, 0);

      /*
       * INFO: Sobald sich die Tabellenkonfiguration ändert, wird diese erneut in den Store übertragen.
       * Das findet asynchron statt (debounceTime), damit sichergestellt ist, dass die Daten zuerst im
       * LocalStorage aktualisiert werden und erst dann die readTableSettingsFromLS-Methode aufgerufen wird.
       */
      this.table.onColumnsChange().pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(0),
      ).subscribe(() => {
        this.readTableSettingsFromLS();
        this.moveSettingsGear();
      });

    });

    this.store.select(OverviewTableSelectors.pageableDto).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(pageableDto => {
      this.pageSize = pageableDto.limit!;
      this.pageIndex = pageableDto.offset! / pageableDto.limit!;
    });

    this.store.select(OverviewTableSelectors.filterDto).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(filterDto => {
      this.searchValue = filterDto.textfilter?.join(' ') || '';
      this.statusFilter = {
        offen: filterDto.offen || false,
        bearbeitet: filterDto.bearbeitet || false,
        festgeschrieben: filterDto.festgeschrieben || false,
        storniert: filterDto.storniert || false,
      };

      if (filterDto.eingangsbeleg && this.eingangAusgangValue.indexOf(BelegbucheinAusgangDTO.Belegeingang) === -1) {
        this.eingangAusgangValue.push(BelegbucheinAusgangDTO.Belegeingang);
      } else if (!filterDto.eingangsbeleg) {
        this.eingangAusgangValue = this.eingangAusgangValue.filter(
          einAusgang => einAusgang !== BelegbucheinAusgangDTO.Belegeingang,
        );
      }

      if (filterDto.ausgangsbeleg && this.eingangAusgangValue.indexOf(BelegbucheinAusgangDTO.Belegausgang) === -1) {
        this.eingangAusgangValue.push(BelegbucheinAusgangDTO.Belegausgang);
      } else if (!filterDto.ausgangsbeleg) {
        this.eingangAusgangValue = this.eingangAusgangValue.filter(
          einAusgang => einAusgang !== BelegbucheinAusgangDTO.Belegausgang,
        );
      }

      this.betragVon = filterDto.betragVon;

      this.betragBis = filterDto.betragBis;

      this.showDubletteValue = filterDto.dublettenOnly;

      this.belegTypValue = filterDto.vorgangsartIds || [];

      this.createdDateValue = {
        from: filterDto.erstelltVon,
        to: filterDto.erstelltBis,
      };

      this.belegDateValue = {
        from: filterDto.datumVon,
        to: filterDto.datumBis,
      };

    });

    this.store.select(OverviewTableSelectors.filterChips).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(filterChips => {
      this.filterChips = filterChips;
    });

    this.store.select(BelegEntitiesSelectors.countedBelege).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(countedBelege => {
      this.countedBelege = countedBelege;
    });

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
      tap(inhaber => {
        this.inhaberId = inhaber?.id;
      }),
      filter((inhaber): inhaber is InhaberDTO => !!inhaber),
      switchMap(inhaber => this.store.select(OverviewTableSelectors.selectedBelege(inhaber.id))),
    ).subscribe(selectedBelege => {
      this.selectedBelege$.next(selectedBelege);
      this.calcDisabledButtons(selectedBelege);
    });

    this.store.select(OverviewTableSelectors.selectedIds).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(selectedIds => {
      this.selectedIds$.next(selectedIds);
    });

    this.store.select(OverviewTableSelectors.areAllIdsSelected).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(areAllIdsSelected => {
      this.areAllIdsSelected$.next(areAllIdsSelected);
    });

    /*
     * INFO: Mappen der `displayedIds` (in der Tabelle anzuzeigenden Belege) auf die entsprechenden `BelegDTOs`.
     * Jede ID in `displayedIds` wird auf das korrespondierende `BelegDTO` im Entity-State abgebildet.
     */
    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
      filter((inhaber): inhaber is InhaberDTO => !!inhaber),
      switchMap(inhaber =>
        this.store.select(OverviewTableSelectors.displayedBelege(inhaber.id)).pipe(
          takeUntil(this.unsubscribe$),
        ),
      ),
    ).subscribe(belege => {
      this.belege = belege;
    });

    combineLatest([
      this.navigationService.inhaberList$,
      this.navigationService.currentInhaber$,
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([
                   allInhaberDtos,
                   selectedInhaberDto,
                 ]) => {

      if (!allInhaberDtos || !selectedInhaberDto) return;

      this.moveDestinationInhaber = allInhaberDtos.filter(inhaberDto => {
        return inhaberDto.id !== selectedInhaberDto.id;
      });
    });

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;

      this.store.select(BelegEntitiesSelectors.vorgangsartDtosByInhaberId(inhaber.id)).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(vorgangsartDtos => {
        this.vorgangsartDtos = vorgangsartDtos;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update des Wertes, nachdem die neuen Inhalte in der Tabelle fertig gerendert wurden.
    setTimeout(this.moveSettingsGear.bind(this), 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Wertet den Status des Beleg aus und gibt das entsprechende Icon zurück
   *
   * @param beleg = Der entsprechende Beleg
   */
  calcBelegStatusIconColor(beleg: BelegDTO): string {
    if (beleg.offen) {
      return '#CCCCCC';
    } else if (beleg.bearbeitet) {
      return '#FFB300';
    } else if (beleg.festgeschrieben) {
      return '#4D9E89';
    } else if (beleg.storniert) {
      return '#FF4747';
    }
    return '';
  }

  calcBelegStatus(beleg: BelegDTO): Status {
    if (beleg.offen) {
      return 'default';
    } else if (beleg.bearbeitet) {
      return 'warning';
    } else if (beleg.festgeschrieben) {
      return 'success';
    } else if (beleg.storniert) {
      return 'abort';
    }
    return 'default';
  }

  calcBelegStatusText(beleg: BelegDTO): string {
    if (beleg.offen) {
      return 'Offen';
    } else if (beleg.bearbeitet) {
      return 'Bearbeitet';
    } else if (beleg.festgeschrieben) {
      return 'Fertiggestellt';
    } else if (beleg.storniert) {
      return 'Storniert';
    }
    return '';
  }

  isStatusOffenOrBearbeitet(beleg: BelegDTO): boolean {
    return this.belegUtilService.isStatusOffenOrBearbeitet(beleg);
  }

  hasDubletten(beleg: BelegDTO): boolean {
    return this.belegUtilService.hasDubletten(beleg);
  }

  isDeletable(beleg: BelegDTO): boolean {
    return this.belegUtilService.isDeletable(beleg);
  }

  isMoveable(beleg: BelegDTO): boolean {
    return this.belegUtilService.isMoveable(beleg);
  }

  isSlicable(beleg: BelegDTO): boolean {
    return this.belegUtilService.isSlicable(beleg);
  }

  isSplicable(beleg: BelegDTO): boolean {
    return this.belegUtilService.isSplicable(beleg);
  }

  /**
   * Triggert das Starten der Sortierung einer einzelnen Spalte
   *
   * $event = Beinhaltete die Spalte und die Reihenfolge der Sortierung
   */
  onSortData(): void {
    if (!this.table) return;

    const sorting: PageableBelegDTOSortingEnum[] = [];

    const sortParams = this.table.sortParams; //INFO: Für single-column-sort ".splice(-1)" anhängen.
    const sortDirs = this.table.sortDirs; //INFO: Für single-column-sort ".splice(-1)" anhängen.

    this.table.sortParams = sortParams;
    this.table.sortDirs = sortDirs;

    for (let i = 0; i < sortParams.length; i++) {
      const direction = sortDirs[i];
      if (direction !== '') {
        switch (sortParams[i]) {
          case BelegListColumn.Erstellt: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Erstelltasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Erstelltdesc);
            }
            break;
          }
          case BelegListColumn.Belegtyp: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Vorgangsartasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Vorgangsartdesc);
            }
            break;
          }
          case BelegListColumn.Partner: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Partnerbezeichnungasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Partnerbezeichnungdesc);
            }
            break;
          }
          case BelegListColumn.Datum: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Datumasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Datumdesc);
            }
            break;
          }
          case BelegListColumn.Betrag: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Betragasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Betragdesc);
            }
            break;
          }
          case BelegListColumn.Buchstelle: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Uploadbuchstelleasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Uploadbuchstelledesc);
            }
            break;
          }
          case BelegListColumn.Status: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Statusasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Statusdesc);
            }
            break;
          }
          case BelegListColumn.BelegbucheinAusgang: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Belegbuchasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Belegbuchdesc);
            }
            break;
          }
          case BelegListColumn.Kommentar: {
            if (direction === 'asc') {
              sorting.push(PageableBelegDTOSortingEnum.Kommentarasc);
            } else {
              sorting.push(PageableBelegDTOSortingEnum.Kommentardesc);
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    this.store.dispatch(OverviewTableActions.updateSortingEnum({sorting}));
    this.readTableSettingsFromLS();
    this.moveSettingsGear();
  }

  openPreview(inhaberId: string, belegId: string): void {
    this.store.dispatch(BelegEntitiesActions.openBelegPreviewDialog({inhaberId, belegId}));
  }

  /**
   * Triggert das Löschen eines Belegs.
   *
   * @param belege = Der vom Anwender ausgewählte Beleg
   */
  deleteOrRevert(belege: BelegDTO[]): void {
    if (belege.length === 0) {
      return;
    }

    if (belege.length === 1 && belege[0].festgeschrieben) {
      const beleg = belege[0];
      // INFO: Sofern es nur einen Beleg gibt, welcher festgeschrieben ist, muss der Beleg storniert werden.

      const data: ReuseBelegData = {
        inhaberId: beleg.inhaberId,
        belegId: beleg.id,
        belegAlreadyReversed: beleg.storniert ? true : false,
        showReuseOption: true,
        isDublette: false,
      };

      this.store.dispatch(BelegStipulatedDialogActions.openBelegStipulatedDialog({data}));
    } else {
      const belegIds = belege.map(beleg => beleg.id);
      this.store.dispatch(DeleteDialogActions.open({belegIds}));
    }
  }

  checkStipulateMandatoryFields(belege: BelegDTO[]): boolean {
    let check = true;

    belege.forEach(beleg => {
      if (
        beleg.belegbucheinAusgang === null ||
        beleg.vorgangsart === null ||
        beleg.belegDatum === null ||
        beleg.partner === null
      ) {
        check = false;
        return;
      }
    });

    return check;
  }

  /**
   * Triggert das Festschreiben von ausgewählten Belegen.
   *
   * @param belegDtos Die vom Anwender ausgewählten Belege.
   */
  stipulate(belegDtos: BelegDTO[]): void {
    if (belegDtos.length === 0) return;

    if (this.checkStipulateMandatoryFields(belegDtos)) {

      /*
       * INFO: Werden mehr als 5 Belege gleichzeitig festgeschrieben, dann den StipulateDialog öffnen.
       * Ansonsten direkt das Stornieren durchführen.
       */
      if (belegDtos.length >= 5) {
        this.store.dispatch(StipulateDialogActions.open({
          belegIds: belegDtos.map(belegDto => belegDto.id),
        }));
      } else {
        for (const belegDto of belegDtos) {

          // INFO: Prüfen, ob offene Belege erst auf "Bearbeitet" gesetzt werden müssen, um sie "Festzuschreiben".
          if (belegDto.offen) {
            this.store.dispatch(BelegEntitiesActions.updateBeleg({
              belegDto,
              stipulateBeleg: true,
            }));
          } else {
            this.store.dispatch(BelegEntitiesActions.stipulateBeleg({
              inhaberId: belegDto.inhaberId,
              belegId: belegDto.id,
            }));
          }
        }
      }
    } else {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Fertigstellen',
          text: 'Beleg kann nicht fertiggestellt werden, da nicht alle Pflichtfelder gefüllt sind',
        },
      });
    }
  }

  /**
   * Triggert das Verschieben von ausgewählten Belegen
   *
   * @param belege = Die vom Anwender ausgewählten Belege
   */
  move(sourceInhaberId: string, belege: BelegDTO[]): void {
    if (!sourceInhaberId) {
      this.logger.error('source-InhaberId could not be found');
      return;
    }
    let belegIds: string[] = belege.map(beleg => beleg.id);
    this.store.dispatch(MoveBelegDialogActions.open({sourceInhaberId, belegIds}));
  }

  /**
   * Selektiert oder deselektiert die Checkboxen aller Belege in der Tabelle.
   */
  masterToggle(event: MatCheckboxChange): void {
    /*
     * INFO: die Werte "checked" und "indeterminate" werden zunächst auf false gesetzt,
     * um sicherzustellen, dass das Setzen der Checkbox über den Store geschieht und nicht
     * über die internen Methoden von MatCheckbox.
     */
    const checkbox = event.source;
    checkbox.checked = false;
    checkbox.indeterminate = false;
    const belegIds = this.belege.map(beleg => beleg.id);
    this.store.dispatch(OverviewTableActions.toggleAll({belegIds}));
  }

  /**
   * Handelt das Verhalten der Aktionen, nachdem die Checkbox eines Belegs getriggert wurde.
   * @param event
   * @param beleg
   */
  checkboxChanged(event: MatCheckboxChange, beleg: BelegDTO): void {
    /*
     * INFO: die Werte "checked" und "indeterminate" werden zunächst auf false gesetzt,
     * um sicherzustellen, dass das Setzen der Checkbox über den Store geschieht und nicht
     * über die internen Methoden von MatCheckbox.
     */
    const checkbox = event.source;
    checkbox.checked = false;
    checkbox.indeterminate = false;
    const belegId = beleg.id;
    const belegIds = [belegId];
    this.store.dispatch(OverviewTableActions.toggleBelege({belegIds}));
  }

  /**
   * Diese Methode bestimmt den Status (aktiviert oder deaktiviert) spezifischer Buttons. Dazu überprüft sie eine Liste
   * ausgewählter Belege, wobei der Status der Buttons von bestimmten Beleg-Eigenschaften (z.B. ob dieser
   * festgeschrieben oder storniert ist, eine bestimmte Seitenanzahl hat oder nicht im PDF-Format vorliegt) abhängt.
   *
   * Aufgerufen werden soll die Methode, sobald sich die Liste der selektierten Elemente ändert.
   */
  calcDisabledButtons(selectedBelege: BelegDTO[]) {
    let moveDeleteDisabled = true;
    let stipulateDisabled = false;
    let hasOnlyOnePage = false;
    let notPDF = false;

    if (selectedBelege.length === 0) {
      moveDeleteDisabled = true;
    } else {
      moveDeleteDisabled = false;

      selectedBelege.forEach(beleg => {

        if (beleg.festgeschrieben || beleg.storniert) {
          moveDeleteDisabled = true;
        }

        if (this.hasDubletten(beleg)) {
          stipulateDisabled = true;
        }

        if (beleg.seitenanzahl !== undefined && beleg.seitenanzahl === 1) {
          hasOnlyOnePage = true;
        }

        if (beleg.contentType !== undefined && beleg.contentType !== this.applicationPdf) {
          notPDF = true;
        }
      });
    }

    this.moveDeleteDisabled = moveDeleteDisabled;
    this.stipulateDisabled = stipulateDisabled;
    this.sliceDisabled = moveDeleteDisabled || hasOnlyOnePage || notPDF;
    this.spliceDisabled = moveDeleteDisabled || notPDF || selectedBelege.length < 2;
  }

  doOpenEditBeleg(selectedBelege: BelegDTO[]): void {
    // INFO: Die Bearbeiten-Seite immer mit einer Liste der Belege öffnen, welche durch die Checkbox angewählten wurden
    let belege = selectedBelege;
    let index = 0;

    // INFO: Sofern keine Checkbox angewählt wurde, die gesamte Liste bei dem Beleg öffnen welcher angeklickt wurde
    if (this.selectedIds$.getValue().length === 0) {
      belege = this.belege;
      index = this.belege.indexOf(selectedBelege[0]);
    }

    this.openEditBeleg.emit({
      belege,
      index,
      inhaberId: selectedBelege[0].inhaberId,
    });

    this.tracker.trackEvent('bo-list', 'edit');
  }

  doOpenHandleDubletteDialog(beleg: BelegDTO): void {
    this.store.dispatch(DubletteActions.openHandleDubletteDialog({
      inhaberId: this.inhaberId!,
      dublettenIds: [
        ...beleg.dubletten!,
        beleg.id,
      ],
    }));
  }

  /**
   * Lädt den Beleg als Datei herunter.
   *
   * @param beleg Der herunterzuladende Beleg.
   */
  doDownloadBeleg(beleg: BelegDTO): void {
    this.store.dispatch(BelegEntitiesActions.downloadBeleg({
      inhaberId: beleg.inhaberId,
      belegId: beleg.id,
      name: beleg.dateiname || 'Beleg',
    }));
  }

  /**
   * Triggert das Heften von ausgewählten Belegen.
   *
   * @param belegDtos Die vom Anwender ausgewählten Belege.
   */
  doSplice(belegDtos: BelegDTO[]): void {
    const belegIdsToSplice = belegDtos.filter(belegDto => {
      return this.isSplicable(belegDto);
    }).map(beleg => beleg.id);

    this.store.dispatch(SpliceActions.setSpliceList({belegIdsToSplice}));
    this.router.navigate(['/splice/inhaber/' + this.inhaberId]);
  }

  /**
   * Triggert das Entheften von ausgewählten Belegen.
   *
   * @param belegDtos Die vom Anwender ausgewählten Belege.
   */
  doSlice(belegDtos: BelegDTO[]): void {
    belegDtos = belegDtos.filter(belegDto => this.isSlicable(belegDto));
    this.store.dispatch(BelegEntitiesActions.entheften({
      belegDtos,
    }));
  }

  /**
   * Triggert das Aktualisieren des PageIndex im Store.
   *
   * @param pageIndex Der aktuelle PageIndex.
   */
  doUpdatePageIndex(pageIndex: number) {
    this.store.dispatch(OverviewTableActions.updatePageIndex({pageIndex}));
  }

  isSelected(beleg: BelegDTO): Observable<boolean> {
    return this.selectedIds$.pipe(
      map(ids => ids.includes(beleg.id)),
    );
  }

  /*
   * INFO: Laden der TableSettings aus dem LocalStorage. Dabei wird auch berücksichtigt, was passiert, wenn neue Spalten
   * hinzukommen, die sich noch nicht im LocalStorage befinden oder überflüssige Spalten entfernt wurden.
   */
  readTableSettingsFromLS(): void {
    try {
      const tableSettingsString = localStorage.getItem(this.tableSettingsDefault._key) || '{}';
      let tableSettings: TableSettings = JSON.parse(tableSettingsString);
      if (!tableSettings._columns) {
        tableSettings = this.tableSettingsDefault;
      }
      tableSettings = {
        ...tableSettings,
        _sortDirs: tableSettings._sortDirs, //INFO: Für single-column-sort ".splice(-1)" anhängen.
        _sortParams: tableSettings._sortParams, //INFO: Für single-column-sort ".splice(-1)" anhängen.
      };


      /*
       * INFO: Falls sich mal der key einer Column ändern sollte oder neue hinzukommen,
       * werden im folgenden Abschnitt entfernte Columns aus den Localstorage-Settings
       * entfernt und neue hinzugefügt. Ansonsten würde es zu Fehlermeldungen kommen.
       */

      const filteredColumns = tableSettings._columns.filter(
        column => this.tableColumns.find(tColumn => tColumn.id === column.id),
      );

      const newColumns = this.tableColumns.filter(
        tColumn => !tableSettings._columns.find(column => tColumn.id === column.id),
      );

      filteredColumns.splice(-1, 0, ...newColumns);
      let filteredSortParams = [...tableSettings._sortParams];
      let filteredSortDirs = [...tableSettings._sortDirs];

      for (let i in tableSettings._sortParams) {
        if (!filteredColumns.find(column => column.id === tableSettings._sortParams[i])) {
          filteredSortParams.splice(parseInt(i), 1);
          filteredSortDirs.splice(parseInt(i), 1);
        }
      }

      tableSettings = {
        ...tableSettings,
        _columns: [
          ...filteredColumns,
        ],
        _sortParams: filteredSortParams,
        _sortDirs: filteredSortDirs,
      };

      this.store.dispatch(OverviewTableActions.changeTableSettings({tableSettings}));
    } catch (error) {
      this.logger.warn('Could not load tablesettings from localstorage', error);
    }
  }

  protected updateSearchFilter(searchValue: string): void {
    this.store.dispatch(OverviewTableActions.updateSearchFilter({searchValue}));
  }

  protected updateStatusFilter(statusFilter: StatusFilter): void {
    this.store.dispatch(OverviewTableActions.updateStatusFilter({
      offen: statusFilter.offen,
      bearbeitet: statusFilter.bearbeitet,
      festgeschrieben: statusFilter.festgeschrieben,
      storniert: statusFilter.storniert,
    }));
  }

  protected setEingangAusgangState(einAusgang: BelegbucheinAusgangDTO[]): void {
    this.store.dispatch(OverviewTableActions.updateEinAusgangFilter({einAusgang}));
  }

  protected setCreatedState(dateFilter: DateFilterData): void {
    this.store.dispatch(OverviewTableActions.updateCreatedDateFilter({dateFilter}));
  }

  protected setBelegDateState(dateFilter: DateFilterData): void {
    this.store.dispatch(OverviewTableActions.updateBelegDateFilter({dateFilter}));
  }

  protected setVorgangsartIds(vorgangsartIds: string[]): void {
    this.store.dispatch(OverviewTableActions.setBelegtypFilter({
      selectedVorgangsartIds: vorgangsartIds,
      allVorgangsartDtos: this.vorgangsartDtos,
    }));
  }

  protected setBetragVon(betragVon: number) {
    this.store.dispatch(OverviewTableActions.setBetragvonFilter({betragVon: betragVon}));
  }

  protected setBetragBis(betragBis: number) {
    this.store.dispatch(OverviewTableActions.setBetragbisFilter({betragBis: betragBis}));
  }

  protected toggleExpansionpanel(): void {
    this.filterVisible$.pipe(
      take(1),
    ).subscribe(isVisible => {
      if (isVisible) {
        this.store.dispatch(OverviewTableActions.hideFilter());
      } else {
        this.store.dispatch(OverviewTableActions.showFilter());
      }
    });
  }

  protected filterDublette(showDubletten: boolean | undefined): void {
    this.store.dispatch(OverviewTableActions.updateShowDublettenFilter({showDubletten}));
  }

  protected resetFilter(id?: string): void {
    if (id) {
      this.store.dispatch(OverviewTableActions.resetSingleFilter({id}));
    }
  }

  protected resetAllFilter(): void {
    this.store.dispatch(OverviewTableActions.resetAllFilter());
  }
}
