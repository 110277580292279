import {Component} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject, take} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IconService} from './services/icon.service';
import {IntentActionService} from './services/intent-action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {ContextInfoService, WellKnownJustFarmingProperties} from './portal/context-info.service';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {UiService} from './services/ui.service';


@Component({
  selector: 'bo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private readonly unsubscribe$ = new Subject<void>();

  runningInPortal$: Observable<boolean>;

  title = 'belege-online';

  constructor(
    iconService: IconService, // INFO: use for instantiation
    intentActionService: IntentActionService, // INFO: use for instantiation
    private portalMessageBroker: PortalMessageBroker,
    private oidcSecurityService: OidcSecurityService,
    private contextInfoService: ContextInfoService,
    private readonly tracker: MatomoTracker,
    private readonly uiService: UiService,
  ) {
    this.runningInPortal$ = this.portalMessageBroker.runningInPortal$
      .pipe(
        takeUntil(this.unsubscribe$),
      );

    this.contextInfoService.contextInfo$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(contextInfo => {
        let analytics = contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS);
        if (analytics === true) {
          let visitorId = contextInfo.properties.get(WellKnownJustFarmingProperties.ANALYTICS_VID)?.toString();
          if (visitorId) {
            this.tracker.setVisitorId(visitorId);
          }
          this.tracker.setConsentGiven();
        } else {
          this.tracker.forgetConsentGiven();
        }
      });
  }

  doLogout(): void {
    this.oidcSecurityService.logoff().pipe(take(1)).subscribe();
  }
}
